<template>
  <div id="loader">
      <img src="/images/loader.gif" title="Загрузка данных" />
  </div>
</template>

<script>

export default {
  name: "Loading"
}

</script>

<style scoped>
</style>


