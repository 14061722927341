import { render, staticRenderFns } from "./LogSummary.vue?vue&type=template&id=40b8b8aa&"
import script from "./LogSummary.vue?vue&type=script&lang=js&"
export * from "./LogSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports